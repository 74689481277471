

















































import { stateModule, userModule } from '@/store'
import { Component, Vue } from 'vue-property-decorator'

import Loader from '@/components/loaders/Loader.vue'
import TextInput from '@/components/inputs/Text.vue'

import { LoginRequest } from '@/requests/auth/LoginRequest'

@Component({
  components: {
    Loader,
    TextInput,
  },
})
export default class Login extends Vue {
  private submitted: boolean = false
  private authRequest: any = null

  private user: LoginRequest = new LoginRequest({})
  private errors: ErrorResponse = {}

  private get appVersion(): string {
    return stateModule.version
  }

  private get loading(): boolean {
    return stateModule.loading
  }

  private created() {
    if (typeof this.$route.query.username === 'string') {
      this.user.username = this.$route.query.username
    }
  }

  private mounted() {
    this.$msal.handleCallback((error: any, response: any) => {
      if (this.authRequest) clearTimeout(this.authRequest)
      
      this.$msal.setIsAuthenticated(!!response)
      this.submitToken(response.idToken.rawIdToken)
    })

    if (this.authRequest) clearTimeout(this.authRequest)

    this.authRequest = setTimeout(() => {
      if (!this.$msal.isAuthenticated) {
        this.singInMsal()
      }
      else this.redirectUser()
    }, 1000)
  }

  private async redirectUser() {
    const redirectionUrl = localStorage.getItem('urlToRedirect')

      if (redirectionUrl) {
        // sessionStorage.removeItem('urlToRedirect')
        await this.$router.push(redirectionUrl)
      }
      else if (this.$route.query.next) {        
        await this.$router.push({ path: this.$route.query.next.toString() })
      } 
      else {
        await this.$router.push({ name: 'dashboard' })
      }
  }

  private async submitToken(token: string) {
    stateModule.setLoading(true)
    await userModule.submitToken(token)

    try {
      this.redirectUser()
    } catch (e) {}
    stateModule.setLoading(false)
  }

  private async signOutMsal() {
    await this.$msal.signOut()
  }

  private async singInMsal() {
    await this.$msal.signIn();
  }

  private destroyed(): void {
    if (this.submitted) {
      stateModule.setLoading(false)
    }
  }

  private resetError(key: string): void {
    this.$set(this.errors, key, [])
  }

  private async submitLogin(): Promise<void> {
    stateModule.setLoading(true)
    this.$set(this, 'errors', {})
    try {
      await userModule.login({ user: this.user })
      this.submitted = true
      try {
        if (this.$route.query.next) {
          await this.$router.push({ path: this.$route.query.next.toString() })
        } else {
          await this.$router.push({ name: 'dashboard' })
        }
      } catch (e) {
        // Navigation duplicate
      }
    } catch (e) {
      stateModule.setLoading(false)
      if (e.status === 419) {
        if (e.data && e.data.message) {
          this.$set(this, 'errors', { message: e.message })
        }
        stateModule.setNotification({ message: 'Your password needs to be reset. Please provide a new, strong, password.', type: 'warning' })
        this.$router.push({ name: 'expired', query: { username: this.user.username } })
      } else if (e.message) {
        this.$set(this, 'errors', { message: e.message })
      }
    }
  }
}
